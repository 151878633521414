<template>
  <div
    ref="refSwiper"
    class="accordion-gallery-mobile swiper swiper-virtual swiper-initialized swiper-horizontal swiper-android swiper-watch-progress"
  >
    <div class="swiper-wrapper">
      <div
        v-for="(slide, index) in slides"
        :key="`slide-${ index }`"
        class="swiper-slide"
      >
        <div class="accordion-gallery-mobile-card">
          <div class="accordion-gallery-mobile-card__image">
            <nuxt-img
              :src="String(slide.imag_id)"
              :placeholder="true"
              width="860"
              loading="lazy"
              :alt="`image-slide-${ index }-${slide.imag_id}`"
            />
          </div>
          <div class="accordion-gallery-mobile-card__body">
            <div
              class="accordion-gallery-mobile-card__title h5"
              v-html="slide.title"
            />
            <div
              v-if="slide.link"
              class="accordion-gallery-mobile-card__button"
            >
              <nuxt-link
                :to="slide.link"
                class="btn btn-primary"
              >
                Подробнее
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      ref="refSwiperPagination"
      class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"
    />
  </div>
</template>

<script setup lang="ts">
import Swiper from 'swiper';
import { Pagination, Virtual } from 'swiper/modules';

interface ISlide {
	link: string | undefined;
	title: string | undefined;
	link_text: string | undefined;
	imag_id: number;
}

interface IProps {
	slides: ISlide[]
}

const props = withDefaults(defineProps<IProps>(), {
  slides: () => []
});

const refSwiper: any = ref(null);
const refSwiperPagination: any = ref(null);
const initSwiper = () => {
  const swiperObj = new Swiper(refSwiper.value, {
    modules: [Pagination, Virtual],
    pagination: {
      el: refSwiperPagination.value,
      enabled: true,
      type: 'bullets'
    },
    virtual: {
      cache: true,
      enabled: true
    },
    init: true,
    spaceBetween: 16,
    slidesPerView: 1.1,
    lazyPreloadPrevNext: 1,
    breakpoints: {
      640: {
        slidesPerView: 2.2
      }
    }
  });
};
onMounted(() => {
  initSwiper();
});

</script>

<style lang="scss">
.accordion-gallery-mobile {
	margin: 0 -20px;
	padding: 0 20px;

	.swiper-pagination {
		position: relative;
		margin-top: 34px;

		& > * {
			opacity: 1;
			width: 10px !important;
			height: 10px !important;
			background-color: #EBEBEB;

			&.swiper-pagination-bullet-active {
				background-color: black;
			}
		}
	}
}

.accordion-gallery-mobile-card {
	border-radius: 16px;
	border: 1px solid #EBEBEB;
	position: relative;
	overflow: hidden;
}

.accordion-gallery-mobile-card__image {
	border-radius: 0 0 16px 16px;
	position: relative;
	overflow: hidden;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&:after {
		content: "";
		float: left;
		padding-top: 62.5%;
	}
}

.accordion-gallery-mobile-card__body {
	padding: 24px;
	box-sizing: border-box;
}

.accordion-gallery-mobile-card__title {
	margin-bottom: 24px;
	color: black;
}

.accordion-gallery-mobile-card__button {
	display: flex;

	.btn {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 48px;
		width: 100%;
		font-weight: 600;
	}
}
</style>
